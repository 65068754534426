<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Rutas</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Rutas</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Sitios"
                    v-if="id != null"
                    >Sitios</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="nombre">Nombre Ruta</label>
                        <input
                          type="text"
                          maxlength="100"
                          class="form-control form-control-sm"
                          id="nombre"
                          v-model="form.nombre"
                          :class="
                            $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="km_pavimentados"
                          >Kilometros Pavimentados</label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="km_pavimentados"
                          v-model="form.km_pavimentados"
                          :class="
                            $v.form.km_pavimentados.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="km_destapados"
                          >Kilometros Destapados</label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="km_destapados"
                          v-model="form.km_destapados"
                          :class="
                            $v.form.km_destapados.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="horas_vigencia">Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.estado"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Sitios">
                  <div class="card-body">
                    <rutaSitio v-if="id != null"></rutaSitio>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('tep.rutas.create')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import rutaSitio from "./rutaSitio";

export default {
  name: "RutaForm",
  components: {
    rutaSitio,
  },
  data() {
    return {
      form: {
        nombre: null,
        km_pavimentados: null,
        km_destapados: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        estados: [],
      },
      id: null,
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      km_pavimentados: {
        required,
      },
      km_destapados: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },

    

    
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/tep/rutas",
          data: this.form,
        })
          .then((response) => {
            if (response.data.status == 0) {
              this.$swal({
                icon: "error",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form = response.data;
              (this.id = this.form.id),
                this.$swal({
                  icon: "success",
                  title: "La Ruta se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

   
    back() {
      return this.$router.replace("/Tep/Rutas");
    },
  },
  mounted() {
    this.init();
    this.getEstados();
  },
};
</script>
